.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
}

.image-container {
  position: relative;
  overflow: hidden;
  max-height: 450px;
  transition: all 1s ease;
}

.image-container img {
  width: 100%;
  height: auto;
  transition: all 0.5s ease;
  opacity: 0.8;
  filter: grayscale(100%);
}

.image-container img:hover {
  filter: none;
  opacity: 1;
}

#portfolio {
  padding-bottom: calc(2rem + 16px);
}

@media (min-width: 968px) {
  .image-grid {
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  }
}

@media (min-width: 1668px) {
  .image-container {
    max-height: 450px;
  }
}
