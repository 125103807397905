.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  transition: all 1s;
  outline: none;
  background-color: #149ddd;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
  padding: 5px;
}
.navbar-left {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all ease-in-out 0.5s;
  z-index: 9997;
  background: #040b14;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.block {
  width: 300px;
}
.none {
  width: 0px;
}
.navbar-left a {
  text-decoration: none;
}
.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  margin-bottom: 5rem;
  margin-top: 5rem;
}
.profile img {
  margin: 15px auto;
  width: 120px;
  border: 8px solid #2c2f3f;
  border-radius: 50%;
  max-width: 100%;
  height: auto;
}
.profile h4 {
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  margin-bottom: 10px;
}
.socials {
  width: 150px;
  text-align: center;
}
.socials a {
  font-size: 18px;
  display: inline-block;
  background: #212431;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
}

.socials a svg {
  transition: all ease-in-out 0.3s;
}

.socials a:hover svg {
  color: #149ddd;
}

.navbar-cont {
  padding-left: 20px;
}
.navbar-cont a {
  display: flex;
  align-items: center;
  color: #a8a9b4;
  padding: 12px 15px;
  margin-bottom: 8px;
  font-size: 15px;
  transition: all 0.5s ease;
}
.navbar-cont a.active {
  color: #149ddd;
}
.navbar-cont a:hover {
  color: #fff;
}
.navbar-footer {
  color: #f4f6fd;
  font-size: 14px;
  text-align: center;
  margin-bottom: 2rem;
  white-space: nowrap;
}
.navbar-footer svg {
  color: #149ddd;
  width: 50px;
  height: 50px;
  margin-left: 10px;
}
