#hero {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)),
    url("../assets/hero-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
#hero h1 {
  font-size: 28px;
  line-height: 45px;
  font-weight: 300;
}
#hero h2 {
  font-weight: 500;
}
#hero span {
  padding-bottom: 4px;
  letter-spacing: 1px;
  border-bottom: 3px solid #149ddd;
}
@media only screen and (min-width: 800px) {
  #hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)),
      url("../assets/hero-img-large.png") !important;
  }
  #hero h1 {
    margin: 0 0 10px 0;
    font-size: 58px;
    font-weight: 700;
    line-height: 56px;
  }
}
@media only screen and (min-width: 1000px) {
  #hero h1 {
    font-size: 68px;
  }
}
